import { List,ListItem } from '@mui/material';
import { Box, Button, Callout } from '@okta/odyssey-react-mui';
import { RefreshIcon } from '@okta/odyssey-react-mui/icons';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const ErrorMessage = ({error, retryAction}) => {
    const validationErrors = error.response?.data?.validationErrors;
    const { t } = useTranslation();

    function displayValidationError(error){
        return (<ListItem>{error.message}</ListItem>)
    }

    let displayMsg = error
    if(error.response?.data?.message){
        displayMsg = error.response?.data?.message
    } else if(error.message){
        displayMsg = error.message
    }
    return (
        <Callout severity='warning' title={t('error-callout-title')}>
            <Fragment>
                <p>{displayMsg}</p>
                {(validationErrors?.length>0) &&
                    <Box className="scrollable">
                        <List>
                        {validationErrors.map((error) =>
                            displayValidationError(error)
                        )}
                        </List>
                    </Box>
                }
                <p>{t('error-callout-persistent')}</p>
                {retryAction ?
                    (<Button variant='primary' onClick={retryAction} startIcon={<RefreshIcon/>} label={t('retry-label')}/>) : null}
            </Fragment>
        </Callout>
    )
}

export default ErrorMessage