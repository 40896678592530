import { Status } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';

const EventStatus = ({approvalState}) => {
    const {t} = useTranslation();
    switch(approvalState){
        case 'approved':
            return <Status label={t('event-state-approved',{ns:'events'})} severity='success'/>
        case 'pending':
            return <Status label={t('event-state-pending',{ns:'events'})} severity='warning'/>
        case 'unapproved':
            return <Status label={t('event-state-unapproved',{ns:'events'})} severity='error'/>
        default:
            return <Status label={approvalState} severity='default'/>
    }
}

export default EventStatus;