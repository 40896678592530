import { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getDemos } from "./services/DemoAPI";

const DemoContext = createContext({ demos: {}, setDemos: () => { }, error: {}, refreshContext: () => { }, isReady: {} })

const DemoContextProvider = ({ children }) => {
    const [error, setError] = useState()
    const [demos, setDemos] = useState([])
    const [isReady, setIsReady] = useState(false)
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const refreshContext = useCallback(async () => {
        setIsReady(false)
        setError()
        if (isAuthenticated) {
            getDemos(await getAccessTokenSilently()).then((response) => {
                response.data.sort((a, b) => a.name.localeCompare(b.name));
                var filtered = response.data.filter(demo => demo.meta_data.creation_source?.startsWith("labs"))
                for (let index = 0; index < filtered.length; index++) {
                    const element = filtered[index];
                    element.labId = element.meta_data?.creation_source?.match("labs\\((?<lab_id>.*)\\)")?.groups?.lab_id
                }
                setDemos(filtered)
                setIsReady(true)
            })
                .catch((error) => { setError(error); setIsReady(false); })
        } else {
            setDemos([]);
            setIsReady(true)
        }
    }, [getAccessTokenSilently, isAuthenticated, setDemos])

    useEffect(() => {
        if (isAuthenticated) {
            refreshContext()
        } else {
            setDemos([])
        }
    }, [refreshContext, isAuthenticated, setDemos])

    return (
        <DemoContext.Provider value={{ demos, setDemos, error, refreshContext, isReady }}>
            {children}
        </DemoContext.Provider>
    )
}
export const useDemoContext = () => useContext(DemoContext)
export default DemoContextProvider