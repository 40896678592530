import { useEffect, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../components/Loader";
import { getEvent } from "../services/EventsAPI";
import { Typography } from "@okta/odyssey-react-mui";
import { Container } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

const EventLaunch = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate()
    const [wait,setWait] = useState(true)
    
    const getDetails = useCallback(async () => {
        if (isAuthenticated) {
            getEvent(await getAccessTokenSilently(), params.eventCode)
                .then(async (response) => {
                    navigate(`/lab/create?course=${response.data.labContent}&eventKey=${params.eventCode}`)
                })
                .catch((error) => {
                    setWait(false)
                })
        }
    }, [getAccessTokenSilently, isAuthenticated, params.eventCode, navigate])

    useEffect(() => {
            getDetails()
    }, [isAuthenticated, getDetails])

    if(wait){    return (
    <Loader/>)
    } else{
        return(
        <Container sx={{width: '100%', height: '100%', display:'flex', flexDirection:'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
            <QuestionMark sx={{fontSize:'3rem'}}/>
            <Typography variant='h2'>{t('eventCodeNotFound')}</Typography>
            <Typography variant='support'>{t('eventCodeNotFoundSupporting')}</Typography>
        </Container>
        )
    }
}

export default EventLaunch;