import { useEffect, useRef } from 'react';

    const useDocumentObserver = (targetId,callback) => {
    const observer = useRef(null); 

    useEffect(() => {
        const mutationCallback = (mutationsList, observer) => {
        callback(mutationsList, observer);
        };

        const targetElement = document.getElementById(targetId);

        if (!targetElement) {
        //Element not found in the DOM.
        return;
        }

        observer.current = new MutationObserver(mutationCallback);

        const observerOptions = {
        childList: true,
        subtree: true,
        };

        observer.current.observe(targetElement, observerOptions);

        // Clean up the observer on unmount
        return () => {
        if (observer.current) {
            observer.current.disconnect();
        }
        };
    }, [targetId, callback]);

  return;
};

export default useDocumentObserver;