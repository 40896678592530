import { Container } from "@mui/material"
import { CircularProgress, Typography } from "@okta/odyssey-react-mui"
import { useTranslation } from 'react-i18next';

export default function Loader() {
    const { t } = useTranslation();
    return (
        <Container sx={{width: '100%', height: '100%', display:'flex', flexDirection:'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
            <Typography variant='support'>{t('loading')}</Typography>
        </Container>
    )
}