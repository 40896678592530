import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Config from './Config'
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, useNavigate } from 'react-router-dom';
import flagsmith from "flagsmith";
import { FlagsmithProvider } from 'flagsmith/react';
import './i18n';

function Auth0ProviderWithRedirectCallback({ children, ...props }) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState?.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} cacheLocation="localstorage" {...props}>
      {children}
    </Auth0Provider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <FlagsmithProvider
      options={{
        environmentID: Config.features.environmentID,
        enableAnalytics: true,
        preventFetch: true
      }}
      flagsmith={flagsmith}>
      <Auth0ProviderWithRedirectCallback
        domain={Config.oidc.issuer}
        clientId={Config.oidc.clientId}
        audience={Config.oidc.audience}
        scope={Config.oidc.scopes}
        redirectUri={Config.oidc.redirectUri}
      >
        <App />
      </Auth0ProviderWithRedirectCallback>
    </FlagsmithProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
