import {
    Button,
    Container,
    Icon,
    List,
    Message
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ErrorMessage = ({ error, retryAction }) => {
    const { t } = useTranslation();

    const validationErrors = error.response?.data?.validationErrors;

    function displayValidationError(error){
        return (<List.Item>{error.message}</List.Item>)
    }

    let displayMsg = error
    if (error.response?.data?.message) {
        displayMsg = error.response?.data?.message
    } else if (error.message) {
        displayMsg = error.message
    }
    return (
        <Message negative icon>
            <Icon name='warning' />
            <Message.Content>
                <Message.Header>{t('error-heading')}</Message.Header>
                <p>{displayMsg}</p>
                {(validationErrors?.length>0) &&
                    <Container className="scrollable">
                        <List bulleted>
                        {validationErrors.map((error) =>
                            displayValidationError(error)
                        )}
                        </List>
                    </Container>
                }
                <p>{t('error-persistent')}</p>
                {retryAction ? (
                    <Button className='brandColor' compact onClick={retryAction}><Icon link name="sync"></Icon>{t('retry')}</Button>) : null}
            </Message.Content>
        </Message>
    )
}
export default ErrorMessage