import { TextField, Button,Box } from "@okta/odyssey-react-mui";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const EditableTextField = ({label, value, onSave, multiline = false, type = 'text'}) => {
    const {t} = useTranslation();
    const [editedValue, setEditedValue] = useState(value)

    const[isEditMode,setIsEditMode] = useState(false)
    if(isEditMode){
        return (
            <>
            <TextField
                label={label}
                value={editedValue}
                onChange={(e) => setEditedValue(e.target.value)}
                isMultiline={multiline}
                type={type}
                endAdornment={
                    <>
                        {!multiline&&
                        <>
                            <Button variant='floating' label={t('cancel',{ns:'events'})} onClick={() => setIsEditMode(false)}/>
                            <Button label={t('save',{ns:'events'})} onClick={()=>{onSave(editedValue); setIsEditMode(false)}}/>
                        </>
                        }
                    </>
                }/>
            {multiline && 
                <Box sx={{maxWidth: '55ch'}}>
                    <Box sx={{display:'flex', justifyContent:'end'}}>
                        <Button variant='floating' label={t('cancel',{ns:'events'})} onClick={() => setIsEditMode(false)}/>
                        <Button label={t('save',{ns:'events'})} onClick={()=>{onSave(editedValue); setIsEditMode(false)}}/>
                    </Box>
                </Box>
            }
            </>
        )
    } else {
        return (
            <TextField label={label} isMultiline={multiline} isReadOnly value={value} endAdornment={<Button label={t('edit',{ns:'events'})} onClick={()=>{setEditedValue(value);setIsEditMode(true)}}/>}/>
        )
    }
}
export default EditableTextField