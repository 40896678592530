import { Button, Container, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import './IDPComponent.css'

const IDPComponent = ({ idp }) => {
    const { t } = useTranslation();

    function renderCloud(param) {
        switch (param) {
            case 'workforce-identity':
                return 'Workforce Identity Cloud';
            case 'customer-identity':
                return 'Customer Identity Cloud';
            default:
                return 'Identity Cloud';
        }
    }

    function handleCopyAction(copyValue) {
        navigator.clipboard.writeText(copyValue)
    }

    return (
        <Container className='component'>
            {idp ? (
                <Container className='component'>
                    <span className='cloudType'>
                        {renderCloud(idp.type)}
                    </span>
                    {idp.state === "active" && idp.type === 'workforce-identity' &&
                        <span><div className='tenantName'>{idp.name + '.okta.com'} <Icon className='clickable' name='copy' onClick={() => handleCopyAction(idp.name + '.okta.com')} /></div></span>
                    }
                    <Button
                        className='launchButton'
                        size='mini'
                        icon='share square'
                        content={t('launch')}
                        labelPosition='right'
                        onClick={() => window.open(idp.dashboard)}
                        disabled={idp.state !== "active"}
                    />

                    {idp.state === "error" &&
                        <span>{t('componentError')}</span>
                    }
                    {idp.state === "queued" &&
                        <span>{t('componentDeploying')}</span>
                    }

                    {idp.type === 'customer-identity' && idp.invite_link &&
                        <Button
                            className='launchButton'
                            size='mini'
                            icon='share square'
                            labelPosition='right'
                            content={t('acceptInvite')}
                            onClick={() => window.open(idp.invite_link)}
                        />
                    }
                </Container>
            )
                : (<Container></Container>)
            }
        </Container>
    )

}
export default IDPComponent