import { TextField, Select, Button, Box } from "@okta/odyssey-react-mui";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const EditableSelectField = ({label, hint, value, onSave, options}) => {
    const {t} = useTranslation();
    const [editedValue, setEditedValue] = useState(value)

    const[isEditMode,setIsEditMode] = useState(false)
    if(isEditMode){
        return (
            <Box sx={{display: 'flex', alignItems: 'end', gap: '0.85714286rem'}}>
                <Select
                    label={label}
                    hint={hint}
                    value={editedValue}
                    onChange={(e) => setEditedValue(e.target.value)}
                    options={options}
                />
                <Box sx={{paddingBottom:'17px'}}>
                    <Button variant='secondary' label={t('cancel',{ns:'events'})} onClick={() => setIsEditMode(false)}/>
                    <Button label={t('save',{ns:'events'})} onClick={()=>{onSave(editedValue); setIsEditMode(false)}}/>
                </Box>
            </Box>
        )
    } else {
        return (
            <TextField
                label={label}
                hint={hint}
                isReadOnly
                value={options.find((e)=> e.value === value)?.text}
                endAdornment={<Button label={t('edit',{ns:'events'})}
                onClick={()=>{setEditedValue(value);setIsEditMode(true)}}/>}
            />
        )
    }
}
export default EditableSelectField