import React from 'react'
import { Box, Button, Typography } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';
import { useOdysseyDesignTokens } from '@okta/odyssey-react-mui';
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@okta/odyssey-react-mui/icons';

const LabProgress = ({ lab, labStep, function_advance, function_regress }) => {
    const { t } = useTranslation();
    const tokens = useOdysseyDesignTokens();

    return (
        <Box sx={{marginTop: tokens.Spacing7}}>
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', justifyContent: 'center'}}>
                <Button
                    startIcon={<ArrowLeftIcon/>}
                    tooltipText={t('previousStep')}
                    isDisabled={labStep - 1 < 0}
                    onClick={function_regress} />
                <Box sx={{margin: tokens.Spacing3}}>
                    <Typography variant='support'>{t('labProgress-stepCount', { step: labStep + 1, totalSteps: lab.steps.length })}</Typography>
                </Box>
                {labStep + 1 !== lab.steps.length 
                    ? <Button
                        startIcon={<ArrowRightIcon/>}
                        tooltipText={t('nextStep')}
                        onClick={function_advance} />
                    : <Button
                        startIcon={<CheckIcon/>}
                        tooltipText={t('completeLab')}
                        onClick={function_advance} /> 
                }
                
            </Box>
        </Box>
    );
};
export default LabProgress;

