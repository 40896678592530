import React, {useCallback} from "react";
import { DataTable, DataTableEmptyState, Status } from "@okta/odyssey-react-mui";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime)

const AttendeeTable = ({data}) => {
  const {t} = useTranslation();

  const columns = [
    {
        accessorKey: "participant",
        header: t('attendee-table-header-attendee',{ns: 'events'}),
    },
    {
        accessorKey: "demoName",
        header: t('attendee-table-header-demoName',{ns: 'events'}),
    },
    {
      id:"progression",
        Cell: ({ cell }) => {
          const lastEvent = cell.getValue()[cell.getValue().length-1]; 
          if(lastEvent.type === "help-required" && lastEvent.description === "Requesting help")
            { return <Status severity="error" label="Requesting help"/>} 
          else{
            return lastEvent.description
          }},
        accessorKey: "progressionEvents",
        header: t('attendee-table-header-progress',{ns: 'events'}),
    },
    {
      id:"progressionTimestamp",
        Cell: ({ cell }) => {const lastEvent = cell.getValue()[cell.getValue().length-1]; return dayjs(lastEvent.completedAt).fromNow()},
        accessorKey: "progressionEvents",
        header: t('attendee-table-header-progressTimeStamp',{ns: 'events'}),
    },
  ]

  const getData = useCallback(async ({search,sort}) => {
    if(data && data !== undefined){
      //sort records by last activity date
      data.sort((a,b)=>{
        if(a.progressionEvents.length === 0 && b.progressionEvents.length === 0){
          return 0
        } else if(a.progressionEvents.length === 0 && b.progressionEvents.length !== 0){
          return -1
        } else if(a.progressionEvents.length !== 0 && b.progressionEvents.length === 0){
          return 1
        } else{
          const eventA = dayjs(a.progressionEvents[a.progressionEvents.length-1].completedAt);
          const eventB = dayjs(b.progressionEvents[b.progressionEvents.length-1].completedAt);
          if(eventA.isSame(eventB)) return 0;
          else if (eventA.isAfter(eventB)) return -1;
          else return 1
        }
      });
      return data
    } else {
      return []
    }
    }, [data],
  );  

  return (
          <DataTable
            columns={columns}
            getData={getData}
            hasPagination={false}
            getRowId={(row) => row.email}
            hasColumnResizing={false}
            hasSorting={false}
            emptyPlaceholder={
              <DataTableEmptyState
                heading={t('attendee-table-empty-state-heading',{ns:'events'})}
                text={<>{t('attendee-table-empty-state-text',{ns:'events'})}</>}
              />
            }
          />
  );
};
export default AttendeeTable;
