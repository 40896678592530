import React from "react";
import ErrorMessage from "./ErrorMessage";
import LabCard from "./LabCard";
import { useTranslation } from 'react-i18next';
import { useDemoContext } from "../DemoContext";
import { Typography, Box } from "@okta/odyssey-react-mui";

const LabBrowser = () => {
    const { t } = useTranslation();
    const demoContext = useDemoContext();

    return (
        <div className="appComponent">
            {demoContext.demos ? (
                <Box>
                    {demoContext.demos.length !== 0 ? (
                        <Box>
                            <Typography variant="h1">{t('browser-heading')}<Typography variant="support">{t('browser-subheading')}</Typography></Typography>
                            <Box sx={{display: 'flex', flexWrap: 'wrap', marginTop:'1.14285614rem'}}>
                                {demoContext.demos.map((lab, index) =>
                                    <LabCard key={index} lab={lab} />
                                )}
                            </Box>
                        </Box>
                    ) : (<Box />)}
                </Box>
            )
                : (<Box>
                    {demoContext.error &&
                        <ErrorMessage error={demoContext.error} retryAction={demoContext.refreshContext()} />}
                </Box>
                )
            }
        </div>
    );
};
export default LabBrowser;
