import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import axios from 'axios';
import VariableProcessor from 'mustache'
import './LabGuide.css'
import { Button, Popup } from 'semantic-ui-react';
import remarkGfm from 'remark-gfm'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import remarkGemoji from 'remark-gemoji';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { Link } from '@okta/odyssey-react-mui';
import rehypeRaw from "rehype-raw";

SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('json', json);

const LabGuide = ({ contentLink, documentRef, variableData = {} }) => {

    const { t } = useTranslation();
    const [contentSource, setContentSource] = useState()
    const [content, setContent] = useState()
    const r = new RegExp('^(?:[a-z+]+:)?//', 'i');

    useEffect(() => {
        const variables = processVariables(variableData)
        let contentUrl
        if(contentLink){
            if(typeof contentLink === 'string'){
                contentUrl = contentLink
            } else {
                if(contentLink[i18n.language]){
                    contentUrl = contentLink[i18n.language]
                }
                else{
                    contentUrl = contentLink['en']
                }
            }
            setContentSource(contentUrl);
            axios.get(contentUrl).then((response) => { setContent(VariableProcessor.render(response.data, variables)) }).catch(err => console.log(err))
        }
    }, [contentLink, variableData])

    function processVariables(variableData) {
        let variables = {}
        if (variableData) {
            if (typeof variableData.idp != "undefined") { variables.idp = variableData.idp }
            if (typeof variableData.demo != "undefined") {
                const instances = []
                if (typeof variableData.demo.app_instances != "undefined") { instances.push(...variableData.demo.app_instances) }
                if (typeof variableData.demo.resource_instances != "undefined") { instances.push(...variableData.demo.resource_instances) }
                for (let instance of instances) {
                    if (typeof instance.label != "undefined") {
                        const instanceKey = instance.label.replace(/\s/g, "")
                        const instanceID = (typeof instance.applicationId === 'undefined') ? instance.resourceId : instance.applicationId
                        variables[instanceKey] = (typeof variables[instanceKey] === 'undefined') ? {} : variables[instanceKey]
                        variables[instanceID] = (typeof variables[instanceID] === 'undefined') ? {} : variables[instanceID]
                        variables[instanceKey].label = instance.label
                        variables[instanceID].label = instance.label
                        if (typeof instance.credentials != "undefined") {
                            variables[instanceKey].credentials = instance.credentials
                            variables[instanceID].credentials = instance.credentials
                        }
                        if (typeof instance.deploymentData != "undefined") {
                            for (const key in instance.deploymentData) {
                                variables[instanceKey][key.replace(/\s/g, "")] = instance.deploymentData[key.replace(/\s/g, "")]
                                variables[instanceID][key.replace(/\s/g, "")] = instance.deploymentData[key.replace(/\s/g, "")]
                            }
                        }
                        if (typeof instance.deploymentHistory != "undefined") {
                            variables[instanceKey].history = instance.deploymentHistory
                            variables[instanceID].history = instance.deploymentHistory
                        }
                    }
                }
            }
        }
        return variables
    }

    function flatten(text, child) {
        return typeof child === 'string'
            ? text + child
            : React.Children.toArray(child.props.children).reduce(flatten, text)
    }

    function HeadingRenderer(props) {
        var children = React.Children.toArray(props.children)
        var text = children.reduce(flatten, '')
        var slug = text.toLowerCase().replace(/\W/g, '-')
        return React.createElement('h' + props.level, { id: slug }, props.children)
    }
    function linkRenderer(props) {
        return <Link href={props.href} target='_blank'>{props.children}</Link>
    }

    function codeRenderer({ node, inline, className, ...props }) {
        const hasLang = /language-(\w+)/.exec(className || 'text');
        return (
            <div className='codeWrapper'>
                <SyntaxHighlighter
                    style={oneDark}
                    language={hasLang ? hasLang[1] : 'text'}
                    PreTag="div"
                    className="codeStyle"
                    showLineNumbers={false}
                    wrapLines={true}
                    wrapLongLines={true}
                    children={props.children}
                />
                <Popup
                    trigger={<CopyToClipboard text={props.children}><Button inverted className='copyBtn' size='mini' icon='copy' /></CopyToClipboard>}
                    closeOnTriggerMouseLeave={true}
                    content={t('copied')}
                    on='click'
                />
            </div>
        )
    }

    function relativeImageExpander(props) {
        var clone = { ...props }
        if (!r.test(props.src)) {
            clone.src = new URL(props.src, contentSource).href
        }
        return React.createElement('img', clone)
    }

    return (
        <div id="labguide" ref={documentRef}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} components={{ h1: HeadingRenderer, h2: HeadingRenderer, code: codeRenderer, img: relativeImageExpander, a: linkRenderer}} className="docframe" remarkPlugins={[remarkGfm, remarkGemoji]}>{content}</ReactMarkdown>
        </div>
    );
};
export default LabGuide;

