import React, { useEffect, useState } from 'react';
import { Segment, Accordion, Icon } from 'semantic-ui-react';
import './TableOfContents.css'
import useDocumentObserver from './DocumentObserver';
import useIntersectionObserver from './InteractionObserver';
import i18n from '../i18n';
import {CheckCircleFilledIcon} from '@okta/odyssey-react-mui/icons'
import {TypographyColorSuccess} from '@okta/odyssey-design-tokens'
import { NotStartedOutlined, RadioButtonCheckedOutlined } from '@mui/icons-material';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Typography } from '@okta/odyssey-react-mui';

const TableOfContents = ({ lab, currentStep, setStep, collapse }) => {

    const [headings, setHeadings] = useState(getHeadings())
    const activeId = useIntersectionObserver(headings.map(({ id }) => id), { rootMargin: "0% 0% -25% 0%" })
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    //monitor the dom of the lab guide for changes and redraw
    useDocumentObserver('labguide', (mutationsList, observer) => {
        setHeadings(getHeadings())
    });

    function getHeadings() {

        const documentHeadings = Array.from(document.querySelectorAll(".docframe h1, .docframe h2"))

        if(documentHeadings && documentHeadings.length > 0){
            const elements = 
                documentHeadings.map((element) => ({
                    id: element.id,
                    text: element.textContent ?? "",
                    level: Number(element.tagName.substring(1))
                }));
            return elements;
        } else {
            return []
        }
    }

    function scrollTo(hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            const yOffset = -70;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        setIsCollapsed(collapse)
    }, [collapse])


    return (
        <Segment basic id='tableofcontents'>
            <Accordion>
                <Accordion.Title onClick={handleToggleCollapse} active={isCollapsed}>
                    <Typography variant='h4'>{lab.title}<Icon className='dropdownIcon' name="dropdown" /></Typography>
                </Accordion.Title>
                <Accordion.Content active={!isCollapsed}>
                    <List className='contentList'>
                        {lab.steps?.map((step, index) => (
                            <>
                            <ListItem key={index}>
                                <ListItemIcon>
                                    {index < currentStep 
                                    ? <CheckCircleFilledIcon sx={{color: TypographyColorSuccess}}/>
                                    : index === currentStep ?
                                        <RadioButtonCheckedOutlined sx={{color: TypographyColorSuccess}}/>
                                        : <NotStartedOutlined/>}</ListItemIcon>
                                <ListItemText onClick={() => {setStep(index)}} className={index === currentStep ? 'activeStep' : ''}>
                                    <Typography variant='h5'>{typeof step.step === 'string' ? step.step : (step.step[i18n.language]? step.step[i18n.language] : step.step['en'])}</Typography>
                                </ListItemText>
                            </ListItem>
                            {index === currentStep &&
                                <List className='subList' component="div" disablePadding>
                                    {headings.map((heading, childIndex) => (
                                        <ListItem className={heading.id === activeId ? 'activeStep' : ''} key={heading.id} as='a' onClick={() => scrollTo(heading.id)}>
                                            <ListItemText>{heading.text}</ListItemText>
                                        </ListItem>))}
                                </List>
                            }
                            </>
                        )
                        )}
                    </List>
                </Accordion.Content>
            </Accordion>
        </Segment>
    )
};

export default TableOfContents;
