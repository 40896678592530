import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import Nav from './components/nav';
import flagsmith from "flagsmith";
import Home from './routes/Home'
import Lab from './routes/Lab'
import Events from './routes/Events'
import EventDetails from './routes/EventDetails';
import { Route, Routes } from 'react-router-dom';
import CreateLab from './routes/CreateLab';
import LabContextProvider from './LabContext';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import Heropa from './components/Heropa';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import DemoContextProvider from './DemoContext';
import { OdysseyThemeProvider } from "@okta/odyssey-react-mui";
import { Box } from '@mui/material';
import Loader from './components/Loader';
import Footer from './components/footer';
import EventLaunch from './routes/EventLaunch';

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user
  } = useAuth0();

  if (error) {
    window.location.replace(`${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENTID}&error=${error.name}&error_description=${error.message}`);
    return null
  }

  if (isAuthenticated) {
    flagsmith.identify(user.email, { connection: user["https://" + process.env.REACT_APP_DOMAIN + "/connection/"] });
  }

  return (
    <Box className="App">
      <I18nextProvider i18n={i18n}>
      <OdysseyThemeProvider>
        <Nav />
        <div className='ContentArea'>
        {!isLoading ? (
          <DemoContextProvider>
            <LabContextProvider>
              <Routes>
                <Route path='/' exact element={<Home />} />
                <Route path='/:eventCode' exact element={<AuthenticationGuard component={EventLaunch}/>}/>
                <Route path='events' exact element={<AuthenticationGuard component={Events} />}/>
                <Route path='events/:eventCode' exact element={<AuthenticationGuard component={EventDetails} />}/>
                <Route path="/lab/create" exact element={<AuthenticationGuard component={CreateLab} />} />
                <Route path="/lab/:demoName" element={<AuthenticationGuard component={Lab} />} />
                <Route path="/desktop" element={<AuthenticationGuard component={Heropa} />} />
              </Routes>
            </LabContextProvider>
          </DemoContextProvider>
        ) : (
          <Loader/>
        )}
        </div>
        <Footer/>
        </OdysseyThemeProvider>
      </I18nextProvider>
    </Box>
  );
}

export default App;
