import { Button, Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const AppComponent = ({ app }) => {
    const { t } = useTranslation();
    return (
        <Container className='component'>
            {app ? (
                <Container className='component'>
                    <span>
                        {app.label}
                    </span>
                    <Button
                        className='launchButton'
                        size='mini'
                        icon='share square'
                        content={t('launch')}
                        labelPosition='right'
                        onClick={() => window.open(app.link)}
                        disabled={app.state !== "active"}
                    />
                    {app.state === "error" &&
                        <span>{t('componentError')}</span>
                    }
                    {app.state === "queued" &&
                        <span>{t('componentDeploying')}</span>
                    }
                </Container>
            )
                : (<Container></Container>)
            }
        </Container>
    )

}
export default AppComponent