import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import './nav.css'
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@okta/odyssey-react-mui';
import { AppBar, Avatar, Toolbar, Container } from '@mui/material';
import { useFlags } from 'flagsmith/react';
import { Link } from 'react-router-dom';

const Nav = () => {
  const flags = useFlags(['event_management']);
  const { t } = useTranslation();
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    logout
  } = useAuth0();
  return (
    <AppBar position='relative'>
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
          <a href='/'>
          <Box sx={{display:'flex', flexDirection:'row'}}><Avatar src="https://cdn.demo.okta.com/images/okta-aura-black.svg" /><Typography color='primary' variant='h2'>labs</Typography><Typography variant='h2'>.demo.okta</Typography></Box>
          </a>
          </Box>
          {!isLoading && isAuthenticated && (
            <Box sx={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
              {flags.event_management?.enabled && <Link to='/events'><Button variant='floating' label='Manage Events'/></Link>}
             <Button variant='secondary' onClick={() => logout({ returnTo: window.location.origin })} label={t('logout')}/>
              <Avatar src={user.picture} avatar sx={{marginLeft:'0.57142857rem'}}/>
            </Box>
          )}
          {!isLoading && !isAuthenticated && (
            <Button onClick={loginWithRedirect} label={t('login')} variant='primary'/>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Nav;
