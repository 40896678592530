import { Dropdown } from 'semantic-ui-react';
import i18n, { SupportedLanguages } from '../i18n';

const LanguagePicker = () => {
    const languages = [];

    function pickLanguage(e, { value }) {
        i18n.changeLanguage(value)
    }

    SupportedLanguages?.forEach(element => {
        languages.push({ key: element, text: element.toUpperCase(), value: element })
    });

    return (
        <Dropdown
            icon='world'
            options={languages}
            closeOnChange
            onChange={pickLanguage}
        />
    )
}
export default LanguagePicker