import { Form, TextField, Button, ToastStack, Toast, Dialog, Select, CircularProgress, Box } from "@okta/odyssey-react-mui";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useState } from "react";
import { createEvent } from "../../services/EventsAPI";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorMessage from "../callouts/ErrorMessage"
import { useTranslation } from "react-i18next";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { useLabContext } from "../../LabContext";

dayjs.extend(utc)

const CreateEventForm = ({open=false, setOpen, completeFunc}) => {

    const { t } = useTranslation();
    const { user, getAccessTokenSilently } = useAuth0();
    const [toast, setToast] = useState(false);
    const [error, setError] = useState();
    const [isWaiting, setIsWaiting] = useState();
    const labContext = useLabContext();

    async function handleSubmit({target}){
        setIsWaiting(true)
        try{
            const at = await getAccessTokenSilently()
            await createEvent(
                at,
                target.label.value,
                target.description.value,
                target.eventType.value,
                target.content.value,
                parseInt(target.participantCount.value),
                dayjs(target.start.value).utc().format(),
                dayjs(target.end.value).utc().format(),
                user.email,
                []
            );
            setToast(true);
            handleClose();
            setIsWaiting(false);
            completeFunc()
        } catch(err){
            setError(err)
            setIsWaiting(false);
        }
    }

    function handleClose(){
        setOpen(false)
    }

    return (<>
        <Dialog 
            PaperProps={{
                component: 'form',
            }}
            title={t('createForm-title',{ns:'events'})}
            isOpen={open}
            onClose={()=>handleClose()}>
                <Form name="guest-invite" onSubmit={(event)=>{event.preventDefault();handleSubmit(event)}} autoCompleteType='on' 
                        formActions={
                            <Box sx={{display:'flex', alignItems: 'center'}}>
                            {isWaiting && <CircularProgress/>}
                            <Button isDisabled={isWaiting} label={t('cancel')} variant='tertiary' type='reset' onClick={()=>handleClose()} />
                            <Button isDisabled={isWaiting} label={t('submit')} variant='primary' type='submit' />
                            </Box>
                        }>
                    <TextField name="label" label={t('createForm-eventTitle',{ns:'events'})} hint={t('createForm-eventTitleHint',{ns:'events'})}/>
                    <TextField name="description" label={t('createForm-eventDescription',{ns:'events'})} isMultiline={true} hint={t('createForm-eventDescriptionHint',{ns:'events'})}/>
                    <Select name="eventType" label={t('createForm-eventType',{ns:'events'})} hint={t('createForm-eventTypeHint',{ns:'events'})} options={[{text:'Pipeline Generation', value:'pipeline'},{text:'Customer Workshop', value:'workshop'}, {text:'Proof of Concept', value:'poc'}]}/>
                    <Select name="content" label={t('createForm-content',{ns:'events'})} hint={t('createForm-contentHint',{ns:'events'})} options={labContext.labs.filter((lab)=> lab.state === 'active').map((lab)=>{return {value:lab.id, text:lab.title}})} />
                    <TextField name="participantCount" label={t('createForm-participantCount',{ns:'events'})} hint={t('createForm-participantCountHint',{ns:'events'})} type='number'/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker name="start" label='Event Start' timezone='UTC'/>
                        <DateTimePicker name="end" label='Event End' timezone='UTC'/>
                    </LocalizationProvider>
                    { error && <ErrorMessage error={error} /> }
            </Form>
        </Dialog>
            <ToastStack>
                <Toast
                    isVisible={toast}
                    autoHideDuration={4000}
                    onHide={()=>setToast(false)}
                    role='status'
                    severity="info"
                    text={t('createForm-acknowledge',{ns:'events'})}
                />
            </ToastStack>
    </>)
}

export default CreateEventForm