import React, {
    useState,
    useEffect
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Icon } from 'semantic-ui-react';
import { useNavigate, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { attachAppToDemonstration, attachResourceToDemonstration, canJoinEvent, createDemonstration, createIDP, getIDP, progressEvent } from "../services/DemoAPI";
import ErrorMessage from "../components/ErrorMessage";
import { useLabContext } from "../LabContext";
import Gravatar from 'gravatar'
import { useDemoContext } from "../DemoContext";
import LabCard from "../components/LabCard";
import { useTranslation } from 'react-i18next';
import LabGuide from "../components/LabGuide";
import RegistrationStates from "../RegistrationStates";
import { useInterval } from "../useInterval";
import { generateDemoName } from "../GenerateDemoNames";
import { Container, Button, Grid, AvatarGroup, Avatar, Table, TableCell, TableRow, Tooltip } from "@mui/material";
import { Box, Callout, CircularProgress, TextField, Typography } from "@okta/odyssey-react-mui";
import * as duration from 'duration-fns'

const CreateLab = () => {

    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const labContext = useLabContext();
    const demoContext = useDemoContext();
    const { getAccessTokenSilently } = useAuth0();

    const [lab, setLab] = useState();
    const [existingDemo, setExistingDemo] = useState();
    const [idp, setIdp] = useState(null);
    const [name, setName] = useState(generateDemoName())
    const [eventCode,setEventCode] = useState(searchParams.get("eventKey"));
    const [registrationState, setRegistrationState] = useState(RegistrationStates.NotRequired)
    const [registrationError, setRegistrationError] = useState()
    const [nameError, setNameError] = useState();
    const [waiting, setWaiting] = useState(false)
    const [error, setError] = useState(null)

    const [idpPollFrequency, setIdpPollFrequency] = useState(null)

    useEffect(() => {
        if (state?.lab) {
            setLab(state.lab)
        }
        else {
            if (labContext.labs && labContext.isReady) {
                if (searchParams.get("course")) {
                    var lab = labContext.labs.find(lab => lab.id === searchParams.get("course"))
                    if (lab) {
                        setLab(lab)
                    } else {
                        navigate('/')
                    }
                }
                else {
                    navigate('/')
                }
            }
        }
    }, [state, searchParams, labContext.labs, navigate, labContext.isReady])

    useEffect(() => {
        if (lab && demoContext.demos && demoContext.isReady) {
            setExistingDemo(null)
            const existingDemo = demoContext.demos?.filter(demo => demo.labId === lab.id)
            if (existingDemo.length > 0) {
                setExistingDemo(existingDemo)
            }
        }
    }, [lab, demoContext.demos, demoContext.isReady])


    function validateForm() {
        let result = true

        //test alphanumeric and hyphen, start & end cannot be hyphen
        const tenantPattern = '^(?!W|-)((?!admin|okta)[a-z-0-9]){3,63}$'
        const tenantRegex = new RegExp(`^${tenantPattern}$`)

        if (!name || name.length < 3 || name.length > 63 || !tenantRegex.test(name)) {
            setNameError(t('createLab-nameRequirements'))
            result = false
        } else {
            setNameError()
        }
        return result
    }

    function handleChange(event) {
        switch (event.target.id) {
            case "name":
                setName(event.target.value)
                break
            case "eventCode":
                setEventCode(event.target.value)
                break
            default:
                break;
        }
    }
    async function submitForm() {
        setRegistrationState(RegistrationStates.Awaiting)
        setWaiting(true)
        setError()
        if (validateForm()) {
            canJoinEvent(await getAccessTokenSilently(),lab.id,eventCode)
                .then((res) =>
                {
                    createIdentityCloud()}
                )
            .catch((err) => { err.response.data.message ? setRegistrationError(err.response.data.message) : setRegistrationError(err.message); setRegistrationState(RegistrationStates.Denied); setWaiting(false)})
        }
    }

    async function createIdentityCloud() {
        var at = await getAccessTokenSilently()
        createIDP(at, name, lab.idp_type,lab.variant)
            .then(async (response) => {
                createDemonstration(at, name, "lab", response.data.idp_id, "labs(" + lab.id + ")",eventCode)
                .then(demoContext.refreshContext())
                .then(()=>{
                    if(eventCode){
                        progressEvent(at,eventCode,name,'Registered', 'registration')
                    }
                })
                    .catch((error) => {
                        if (error.response?.status === 409) {
                            setNameError(t('createLab-conflict'))
                            setWaiting(false)
                        }
                        else {
                            setError(error);
                            setWaiting(false)
                        }
                    }
                    ).then(()=>{
                        setIdp(response.data)
                        setIdpPollFrequency(2000)
                    })
            })
            .catch((error) => {
                if (error.response?.status === 409) {
                    setNameError(t('createLab-conflict'))
                    setWaiting(false)
                }
                else {
                    setError(error);
                    setWaiting(false)
                }
            }
            )
    }

    useInterval(async () => {
        if (idp) {
            var response = await getIDP(await getAccessTokenSilently(), idp.idp_id)
            setIdp(response.data)
        }
    }, idpPollFrequency)

    useEffect(() => {
        if (idp) {
            switch (idp.state) {
                case 'error':
                    setIdpPollFrequency(null);
                    setWaiting(false)
                    setError(t('idpError'))
                    break;
                case 'active':
                    setIdpPollFrequency(null);
                    getAccessTokenSilently().then((at) => {
                        var dependencies = []
                        lab.applications?.forEach(element => {
                            dependencies.push(attachAppToDemonstration(at, name, element, undefined, undefined))
                        });
                        lab.resources?.forEach(element => {
                            dependencies.push(attachResourceToDemonstration(at, name, element, undefined, undefined))
                        })
                        return Promise.all(dependencies).then(() => {
                            navigate('/lab/' + name)
                        })
                    }
                    )
                    break
                default:
                    break
            }
        }
    }, [idp, getAccessTokenSilently, lab, name, navigate, t])

    function displayDuration(durationString){
        try{
            var durationObj = duration.parse(durationString);
            return (durationObj.hours ? `${durationObj.hours}  ${t('hours')} ` : "") + (durationObj.minutes ? `${durationObj.minutes} ${t('minutes')}` : "")

        } catch(err){
            return durationString
        }
    }


    if (lab) {
        return (
            <Container sx={{marginBottom: '3.42857143rem'}}>
                <Grid container sx={{marginBottom: '2.85714286rem'}}>
                    <Grid item>
                        <Typography variant='h1'>{lab.title}</Typography>
                        <Box>
                            <Table>
                                {lab.duration &&
                                    <TableRow>
                                        <TableCell sx={{borderBottom:'none', padding:'0.28571429rem'}}><Typography variant='support'>{t('duration')}</Typography></TableCell>
                                        <TableCell sx={{borderBottom:'none', padding:'0.28571429rem'}}><Typography variant='support'>{displayDuration(lab.duration)}</Typography></TableCell>
                                    </TableRow>
                                }
                                {lab.contributors &&
                                    <TableRow>
                                        <TableCell sx={{borderBottom:'none', verticalAlign:'middle', padding:'0.28571429rem'}}><Typography variant='support'>{t('publishedBy')}</Typography></TableCell>
                                        <TableCell sx={{borderBottom:'none', padding:'0.28571429rem'}}>
                                            {Array.isArray(lab.contributors) ?
                                                (<AvatarGroup max={5} sx={{justifyContent: 'flex-end'}}>
                                                    {lab.contributors.map((contributor) => (
                                                    <Tooltip title={contributor}><Avatar alt={contributor} src={Gravatar.url(contributor, { protocol: 'https', s: '100', d: 'https://cdn.demo.okta.com/images/okta-icon.png' })} /></Tooltip>
                                                        ))}
                                                </AvatarGroup>)
                                                : <Typography variant='support'>{lab.contributors}</Typography>
                                            }
                                        </TableCell>
                                    </TableRow>
                                }
                            </Table>
                        </Box>
                    </Grid>
                </Grid>  

                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} sx={{paddingRight: '0.57142857rem'}}>
                        {lab.overview ?
                            (<Box><Typography variant='h2'>{t('createLab-description')}</Typography><LabGuide contentLink={lab.overview}></LabGuide></Box>)
                            : (<Box><Typography variant='h2'>{t('createLab-description')}</Typography>{lab.description}</Box>)
                        }
                    </Grid>
                    {(() => {
                        if (demoContext.isReady) {
                            if (existingDemo?.length > 0) {
                                return (
                                    <Grid item xs={4}>
                                        <Typography variant='h2'>Resume Lab</Typography>
                                        <Box sx={{display: 'flex', flexWrap: 'wrap', marginTop:'1.14285614rem'}}>
                                            {existingDemo.map((lab, index) =>
                                                <LabCard key={index} lab={lab} />)}
                                        </Box>
                                    </Grid>
                                )
                            }
                            else{
                                return (
                                    <Grid item xs={12} md={4}>
                                        <Typography variant='h2'>{t('getStarted')}</Typography>
                                            <>
                                                <Box>
                                                    <TextField
                                                    id='name'
                                                    value={name}
                                                    onChange={handleChange}
                                                    errorMessage={nameError}
                                                    isReadOnly={true}
                                                    label={t('createLab-nameLabel')}
                                                    hint={t('createLab-namePopup')}
                                                    endAdornment={<Button disabled={waiting} variant='tertiary' onClick={() => { setName(generateDemoName()) }} ><Icon name='refresh'/></Button>}
                                                    />
                                                    <TextField
                                                    id='eventCode'
                                                    value={eventCode}
                                                    onChange={handleChange}
                                                    isDisabled={waiting}
                                                    label={t('event code')}
                                                    hint={t('event-code-hint')}/>
                                                </Box>
                                            <Box sx={{marginTop: '0.85714285rem', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <Button disabled={waiting} variant='primary' onClick={submitForm}>{t('launch')}</Button>
                                                {waiting &&
                                                    <Box sx={{display:'flex', marginLeft: '1.14285714rem', alignItems: 'center', gap: '0.57142857rem'}}><CircularProgress/><Typography variant='support'>{idp ? t('idp-' + idp.state) : t('loading')}</Typography></Box>
                                            }</Box>
                                            </>
                                            {registrationState === RegistrationStates.Denied &&
                                                <Box sx={{marginTop: '0.85714285rem'}}>
                                                    <Callout  title={t('denyLabRegistration')} text={registrationError} severity='error' />
                                                </Box>
                                            }
                                        </Grid>
                                )
                                } 
                        } else {
                            return (<CircularProgress/>)
                        }
                    })()
                    }
                </Grid>
                {
                    error ? (
                        <ErrorMessage error={error} />
                    ) : null
                }
            </Container >
        )
    } else {
        return (
            <Container>
            </Container>
        )
    }
}
export default CreateLab;