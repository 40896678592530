import { Box, Tile, Button } from '@okta/odyssey-react-mui';
import { useTranslation } from 'react-i18next';

const LabCatalogCard = ({ lab, waiting, attachEvent }) => {
    const { t } = useTranslation();
    if (lab.state !== 'hidden') {
        return (
            <Box sx={{width: 300, height: 300, paddingBottom: '1.14285614rem', paddingRight:'1.14285614rem'}}>
                <Tile
                    key={lab.title}
                    button={<Button label={t('catalogCard-cta')} variant="primary" onClick={() => attachEvent(lab)} disabled={lab.state !== 'active' && lab.state !== 'preview'}/>}
                    description={lab.description}
                    overline={lab.idp_type === 'customer-identity' || lab.idp_type === 'okta-customer-identity' ? 'Customer Identity' : 'Workforce Identity'}
                    title={lab.title}
                />
            </Box>
        )
    }
    else { return ("") }

}
export default LabCatalogCard