import axios from 'axios'
import Config from "../Config";

export function getEvents(accessToken) {
  return axios.get(Config.resourceServer.demoAPI + "/events",
    {
      headers: { Authorization: "Bearer " + accessToken }
    }
  )
}

export function createEvent(accessToken, label, description, type, labContent, expectedParticipantCount, eventStart, eventEnd, runner, supporting){
    return axios.post(Config.resourceServer.demoAPI + "/events",
        { label,description, labContent, type, expectedParticipantCount, eventStart, eventEnd, runner, supporting },
        { headers: { Authorization: "Bearer " + accessToken } }
      )
}

export function updateEvent(accessToken, eventCode, label, description, type, labContent, expectedParticipantCount, eventStart, eventEnd, runner, supporting){
  return axios.patch(Config.resourceServer.demoAPI + "/events/"+eventCode,
    { label,description, labContent, type, expectedParticipantCount, eventStart, eventEnd, runner, supporting },
    { headers: { Authorization: "Bearer " + accessToken } }
  )
}

export function deleteEvent(accessToken, eventCode){
  return axios.delete(Config.resourceServer.demoAPI+'/events/'+eventCode,
    {
      headers: { Authorization: "Bearer " + accessToken }
    }
);
}

export function getEvent(accessToken, eventCode){
    return axios.get(Config.resourceServer.demoAPI+'/events/'+eventCode,
        {
          headers: { Authorization: "Bearer " + accessToken }
        }
    );
}

export function getEventProgress(accessToken, eventCode){
  return axios.get(Config.resourceServer.demoAPI+'/events/'+eventCode+'/progress',
    {
      headers: {Authorization: "Bearer "+accessToken}
    });
}