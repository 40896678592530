import React, {
    useState,
    useEffect,
    useCallback,
    createRef
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Sticky } from 'semantic-ui-react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LabGuide from "../components/LabGuide";
import { getIDP, getDemonstration, getApplicationInstance, getResourceInstance, progressEvent } from "../services/DemoAPI";
import { useLabContext } from "../LabContext";
import flagsmith from "flagsmith";
import ErrorMessage from "../components/ErrorMessage";
import LabSegment from "../components/LabSegment";
import LabProgress from "../components/LabProgress";
import TableOfContents from "../components/TableOfContents";
import EmbeddedGuide from "../components/EmbeddedGuide";
import i18n from "../i18n";
import { Typography } from "@okta/odyssey-react-mui";
import Loader from "../components/Loader";

const Lab = () => {
    let params = useParams();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [demo, setDemo] = useState()
    const [idp, setIDP] = useState()
    const [lab, setLab] = useState()
    const [labStep, setLabStep] = useState(0)
    const [error, setError] = useState(null)
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const labContext = useLabContext();
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    const docRef = createRef()

    const getDemo = useCallback(async () => {
        setError()
        if (isAuthenticated) {
            getDemonstration(await getAccessTokenSilently(), params.demoName)
                .then(async (response) => {
                    for (let index = 0; index < response.data.app_instances.length; index++) {
                        const element = response.data.app_instances[index];
                        response.data.app_instances[index] = (await getApplicationInstance(await getAccessTokenSilently(), params.demoName, element.applicationId)).data
                    }

                    for (let index = 0; index < response.data.resource_instances.length; index++) {
                        const element = response.data.resource_instances[index];
                        response.data.resource_instances[index] = (await getResourceInstance(await getAccessTokenSilently(), params.demoName, element.resourceId)).data
                    }
                    response.data.app_instances.sort((a, b) => a.label.localeCompare(b.label));
                    setDemo(response.data);
                    getIDP(await getAccessTokenSilently(), response.data.idp_id)
                        .then(response => { setIDP(response.data) })
                        .catch(err => { setError(err) })
                })
                .catch((error) => { setError(error); })
        } else {
            setDemo();
        }
    }, [getAccessTokenSilently, isAuthenticated, params.demoName])

    useEffect(() => {
        if (!demo) {
            getDemo()
        }
        if (demo && demo.state !== "active") {
            var handle = (setTimeout(() => getDemo(), 5000))
            return () => {
                clearTimeout(handle)
            }
        }
    }, [demo, getDemo])

    useEffect(() => {
        if (demo && !lab) {
            var labId = demo.meta_data.creation_source.match("labs\\((?<lab_id>.*)\\)").groups.lab_id
            var resolvedLab = labContext.labs.find(lab => lab.id === labId)
            var savedStep = flagsmith.getTrait(demo.name)
            if (savedStep) {
                setLabStep(savedStep)
            }
            setLab(resolvedLab)
        }
        if (lab) {
            if (labStep > lab.steps?.length) {
                setLabStep(0)
            }
        }
    }, [demo, labContext.labs, lab, labStep])

    function advanceStep() {
        const navigatedStep = labStep + 1;
        if (navigatedStep !== lab.steps.length) {
            flagsmith.setTrait(demo.name, navigatedStep)
            setLabStep(navigatedStep)
            window.scrollTo(0, 0)
            if(demo.eventCode){
                getAccessTokenSilently().then((at)=> {
                    progressEvent(
                        at,
                        demo.eventCode,
                        demo.name,
                        `Progressed to step ${navigatedStep+1} (${typeof lab.steps[navigatedStep]?.step === 'string' ? lab.steps[navigatedStep]?.step : (lab.steps[navigatedStep]?.step['en'])})`,
                        'step')})
            }
        } else {
            if(demo.eventCode){
                getAccessTokenSilently().then((at)=> {
                    progressEvent(
                        at,
                        demo.eventCode,
                        demo.name,
                        `Completed the lab`,
                        'completion')})
            }
            navigate('/')
        }
    }

    function regressStep() {
        const navigatedStep = labStep - 1;
        if (navigatedStep >= 0) {
            flagsmith.setTrait(demo.name, navigatedStep)
            setLabStep(navigatedStep)
            window.scrollTo(0, 0)
            if(demo.eventCode){
                getAccessTokenSilently().then((at)=> {
                    progressEvent(
                        at,
                        demo.eventCode,
                        demo.name,
                        `Regressed to step ${navigatedStep+1} (${typeof lab.steps[navigatedStep]?.step === 'string' ? lab.steps[navigatedStep]?.step : (lab.steps[navigatedStep]?.step['en'])})`,
                        'step')})
            }
        }
    }

    function setStep(step) {
        flagsmith.setTrait(demo.name, step)
        setLabStep(step)
        window.scrollTo(0, 0)
        if(demo.eventCode){
            getAccessTokenSilently().then((at)=> {
                progressEvent(
                    at,
                    flagsmith.getTrait(demo.name+'.event'),demo.name,
                    `Navigated to step ${step} (${typeof lab.steps[step]?.step === 'string' ? lab.steps[step]?.step : (lab.steps[step]?.step['en'])})`,
                    'step')})
        }
    }

    return (
        <>
            {error ? (<ErrorMessage error={error} />) : null}
            {demo && lab && idp ?
                searchParams.has('LaunchPanel') ?
                    (
                        <LabSegment lab={lab} demo={demo} idp={idp} labStep={labStep} />
                    ) :
                        lab.steps ? (
                        <Grid stackable columns={3}>
                            <Grid.Column width={3}>
                                <Sticky context={docRef} active={true}>
                                    <TableOfContents lab={lab} currentStep={labStep} setStep={setStep} collapse={!matches}></TableOfContents>
                                    {!matches && <LabSegment lab={lab} demo={demo} idp={idp} labStep={labStep} collapse={!matches} />}
                                </Sticky>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Typography variant="h1">{typeof lab.steps[labStep]?.step === 'string' ? lab.steps[labStep]?.step : (lab.steps[labStep]?.step[i18n.language]?lab.steps[labStep]?.step[i18n.language]:lab.steps[labStep]?.step['en'])}</Typography>
                                <LabGuide contentLink={lab.steps[labStep]?.guide} documentRef={docRef} variableData={{ idp: idp, demo: demo }} />
                                <LabProgress lab={lab} labStep={labStep} function_advance={advanceStep} function_regress={regressStep} />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Sticky context={docRef} active={true}>
                                    {matches && <LabSegment lab={lab} demo={demo} idp={idp} labStep={labStep} />}
                                </Sticky>
                            </Grid.Column>
                        </Grid>
                        ): (
                            <>
                             <LabSegment lab={lab} demo={demo} idp={idp} vertical={true}/>
                            <EmbeddedGuide component={demo.resource_instances.find(resource => resource.resourceId === lab.embed)}/>
                            </>
                        )
                     : (
                        <Loader/>
                )}
        </>
    )
}
export default Lab;