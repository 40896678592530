import { useNavigate } from "react-router-dom";
import { useLabContext } from '../LabContext';
import { useAuth0 } from "@auth0/auth0-react";
import { useDemoContext } from '../DemoContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import flagsmith from "flagsmith";
import { deleteDemo } from '../services/DemoAPI';
import { Box, Tile, Button, Dialog, MenuItem } from '@okta/odyssey-react-mui';

const LabCard = ({ lab }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const labContext = useLabContext();
    const [labContent, setLabContent] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [waiting, setWaiting] = useState(false)
    const { getAccessTokenSilently } = useAuth0();
    const demoContext = useDemoContext()
    function handleOnClick(path) {
        navigate(path)
    }

    useEffect(() => {
        if (lab) {
            if (lab.labId && labContext.labs) {
                var content = labContext.labs.find(catalog => catalog.id === lab.labId)
                if (content) {
                    setLabContent(content)
                } else {
                    return setLabContent({ title: 'Unknown Lab', state: 'error' })
                }
            } else {
                return setLabContent({ title: 'Unknown Lab', state: 'error' })
            }
        }
    }, [lab, labContext])


    function daysTillExpiry(expiry) {
        var diff = new Date(expiry).getTime() - new Date().getTime();
        var days = Math.max(Math.round(diff / (1000 * 3600 * 24)), 0);
        return t('labCard-remainingCount', { count: days })
    }

    if (lab && labContent) {
        return (
            <Box sx={{width: 300, height: 300, paddingBottom: '1.14285614rem', paddingRight:'1.14285614rem'}}>
                <Tile
                    key={lab.name}
                    button={<Button label={t('labCard-cta')} variant="primary" onClick={() => handleOnClick(`/lab/${lab.name}`)} disabled={labContent.state === 'error'}/>}
                    description={lab.meta_data.expires_on ? daysTillExpiry(lab.meta_data.expires_on) : ""}
                    image={labContent.idp_type === 'customer-identity' ? <img alt='Customer Identity Cloud Logo' src='https://cdn.demo.okta.com/images/okta/CIC/icon_black.png'/> : <img alt='Workforce Identity Cloud Logo' src='https://cdn.demo.okta.com/images/okta/WIC/icon_black.png'/>}
                    menuButtonChildren={<><MenuItem variant='destructive' onClick={() => setModalOpen(true)}>{t('delete')}</MenuItem></>}
                    overline={lab.name}
                    title={labContent.title}
                />
                <Dialog
                    key={'delete'+lab.name}
                    isOpen={modalOpen}
                    title={t('delete')}
                    primaryCallToActionComponent={<Button disabled={waiting} label="Delete" onClick={async () => {
                        setWaiting(true);
                        setModalOpen(false);
                        await deleteDemo(await getAccessTokenSilently(), lab.name);
                        flagsmith.setTrait(lab.name, null)
                        demoContext.refreshContext();
                        setWaiting(false);
                    }} variant='danger'/>}
                    secondaryCallToActionComponent={<Button disabled={waiting} label="Cancel" onClick={()=>{setModalOpen(false)}} variant='secondary'/>}
                    >
                    {t('deleteModal-heading', { labName: lab.name })}
                </Dialog>
           </Box> 
        )
    } else {
        return (null)
    }

}
export default LabCard