import { useState, useEffect } from 'react';
import { Segment, Container, Header, Grid, AccordionContent, Accordion, Icon } from 'semantic-ui-react';
import AppComponent from './AppComponent';
import IDPComponent from './IDPComponent';
import './LabSegment.css'
import ResourceComponent from './ResourceComponent';
import {BugIcon, ChatIcon} from '@okta/odyssey-react-mui/icons'
import { Link } from '@okta/odyssey-react-mui';
import { useFlags } from 'flagsmith/react';
import { Button } from '@okta/odyssey-react-mui';
import { useAuth0 } from "@auth0/auth0-react";
import { progressEvent } from "../services/DemoAPI";

const LabSegment = ({ demo, lab, idp, labStep, collapse, vertical = false }) => {

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [helpRequested, setHelpRequested] = useState(false)
    const { getAccessTokenSilently } = useAuth0();

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const flags = useFlags(['bug_report_link','request_help'])

    useEffect(() => {
        setIsCollapsed(collapse);
    }, [collapse])

    useEffect(()=>{
        setHelpRequested(false);
    },[labStep]);

    const sendHelpRequest = () =>{
        getAccessTokenSilently().then((at)=> {
            progressEvent(
                at,
                demo.eventCode, demo.name,
                helpRequested ? 'No longer need help' : `Requesting help`,
                'help-required')}).then(setHelpRequested(!helpRequested))
    }

    return (
        <Segment secondary className="labSegment">
            {idp && lab && demo ? (
                <Container className='components'>
                    <Accordion fluid>
                        <Accordion.Title onClick={handleToggleCollapse} active={isCollapsed}>
                            <Header className='labHeader'>{demo.name}<Icon className='dropdownIcon' name="dropdown" /></Header>
                        </Accordion.Title>
                        <AccordionContent active={!isCollapsed}>
                            <Grid stackable>
                                <Grid.Column width={16} className={vertical ? 'verticalLayout':'columnLayout'}><IDPComponent idp={idp} /></Grid.Column>
                                {[...demo.app_instances].sort((a, b) => a.label.localeCompare(b.label)).map((app_instance, index) =>
                                    lab.steps[labStep]?.applications?.includes(app_instance.applicationId) ? (
                                        <Grid.Column width={16} className={vertical ? 'labComponent verticalLayout':'labComponent columnLayout'} key={app_instance.id + "" + index}><AppComponent app={app_instance} /></Grid.Column>) : null
                                )}
                                {[...demo.resource_instances].sort((a, b) => a.label.localeCompare(b.label)).map((resource_instance, index) =>
                                    <>
                                        {lab.steps ?
                                            <>
                                            { lab.steps[labStep]?.resources?.find(x => x === resource_instance.resourceId) ? (
                                                <Grid.Column width={16} className={vertical ? 'labComponent verticalLayout':' labComponent columnLayout'} key={resource_instance.id + "" + index}><ResourceComponent resource={resource_instance} /></Grid.Column>) : null
                                            }
                                            </>
                                            : null
                                        }
                                    </>
                                )}
                            </Grid>
                            {flags.bug_report_link.enabled &&
                                <Grid.Column width={16} className={vertical ? 'component labComponent verticalLayout':' component labComponent columnLayout'}>
                                    <Link icon={<BugIcon/>} href={flags.bug_report_link.value} variant='monochrome' target='_blank' >Report Lab Issue</Link>
                                </Grid.Column>
                            }
                            {flags.request_help.enabled && demo.eventCode &&
                                <Grid.Column width={16} className={vertical ? 'component labComponent verticalLayout':' component labComponent columnLayout'}>
                                    <Button startIcon={<ChatIcon/>} type='button' variant={helpRequested ? 'danger':'secondary'} label={helpRequested ? 'Cancel request help' : 'Request help'} onClick={()=>{sendHelpRequest()}}/>
                                </Grid.Column>
                            }
                        </AccordionContent>
                    </Accordion>
                </Container>
            )
                : (<Container></Container>)
            }
        </Segment>
    )
}
export default LabSegment