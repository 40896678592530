import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Dimmer, Loader } from 'semantic-ui-react';
import React from "react";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <Dimmer inverted active>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    ),
  });

  return <Component />;
};