import { Avatar, List, ListItem, IconButton, ListItemAvatar } from "@mui/material";
import { Box, Typography, TextField, Button } from "@okta/odyssey-react-mui";
import { DeleteIcon, UserIcon } from "@okta/odyssey-react-mui/icons";
import Gravatar from "gravatar"
import { useTranslation } from "react-i18next";
import { updateEvent } from "../../services/EventsAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import EditableTextField from "../fields/EditableTextField";

const EventStaffingTab = ({event,setEvent,setError}) => {
    const {t} = useTranslation();
    const {getAccessTokenSilently} = useAuth0();
    const [staffingAdd, setStaffingAdd] = useState()

    async function addSupporting(){
        setError(undefined)
        var updatedStaffing = event.supporting
        updatedStaffing.push(staffingAdd)
        getAccessTokenSilently()
            .then((at) => {
                updateEvent(at,event.eventCode,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,updatedStaffing)
                    .then((response)=> {
                        setEvent(response.data)
                    })
            .catch((err)=>{event.supporting.pop();setError(err);})})
        setStaffingAdd(null)
    }
    async function removeSupporting(user){
        setError(undefined);
        var index = event.supporting.indexOf(user)
        if(index !== -1){
            var updatedStaffing = event.supporting
            updatedStaffing.splice(index,1)
            getAccessTokenSilently()
                .then((at) => {
                    updateEvent(at,event.eventCode,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,updatedStaffing)
                    .then((response)=>{setEvent(response.data)}).catch((err) => { setError(err);})
                    })
        }
    }

    return (
        <Box>
            <Box sx={{marginBottom: '1.14285714rem'}}>
                <Typography variant='h4'>{t('event-organiser',{ns:'events'})}  <Typography variant='support'>{t('event-organiser-hint',{ns:'events'})}</Typography></Typography>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '0.57142857rem'}}><Avatar src={Gravatar.url(event.meta_data?.created_by, { protocol: 'https', s: '75', d: 'https://cdn.demo.okta.com/images/okta-icon.png' })}/><TextField value={event.meta_data?.created_by} isReadOnly/></Box>
            </Box>
            <Box sx={{marginBottom: '1.14285714rem'}}>
                <Typography variant='h4'>{t('event-runner',{ns:'events'})} <Typography variant='support'>{t('event-runner-hint',{ns:'events'})}</Typography></Typography>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '0.57142857rem'}}>
                    <Avatar src={Gravatar.url(event.runner, { protocol: 'https', s: '75', d: 'https://cdn.demo.okta.com/images/okta-icon.png' })}/>
                    <EditableTextField value={event.runner} 
                    onSave={(value)=>{
                        getAccessTokenSilently()
                            .then((at) => {
                                updateEvent(at,event.eventCode,undefined,undefined,undefined,undefined,undefined,undefined,undefined,value,undefined)
                                    .then((response)=> {
                                        setEvent(response.data)
                                    })
                            .catch((err)=>{setError(err);})
                            })
                    }}/>
                </Box>
            </Box>
            <Box sx={{marginBottom: '1.14285714rem'}}>
                <Typography variant='h4'>{t('event-supporting',{ns:'events'})} <Typography variant='support'>{t('event-supporting-hint',{ns:'events'})}</Typography></Typography>
                <List id="staffingList">
                    {event.supporting?.length > 0 ?
                    (
                        event.supporting.map((user) => {return <ListItem sx={{maxWidth:'38rem'}} secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={()=>removeSupporting(user)}>
                              <DeleteIcon/>
                            </IconButton>
                          }><ListItemAvatar><Avatar src={Gravatar.url(user, { protocol: 'https', s: '75', d: 'https://cdn.demo.okta.com/images/okta-icon.png' })}/></ListItemAvatar>{user}</ListItem>})
                    ): (
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '0.57142857rem'}}><Avatar><UserIcon/></Avatar><Typography variant='support'>{t('event-staffing-empty',{ns:'events'})}</Typography></Box>
                    )}
                    <TextField value={staffingAdd} type='email' onChange={(e) => {setStaffingAdd(e.target.value)}} endAdornment={<Button label={t('event-staffing-add',{ns:'events'})} onClick={()=>addSupporting()}/>}/>
                </List>
            </Box>
        </Box>
    )
}

export default EventStaffingTab