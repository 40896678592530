import React, { useCallback } from "react";
import { Button, DataTable, DataTableEmptyState } from "@okta/odyssey-react-mui";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import EventStatus from "../callouts/EventStatus";
import { useLabContext } from "../../LabContext";

const EventsTable = ({events}) => {
  const labContext = useLabContext();
  const {t} = useTranslation();

  function rowMenuItems(row) {
    if(row?.original?.eventCode){
      return <Link to={'/events/'+row.original.eventCode}><Button label={t('event-table-details',{ns:'events'})} /></Link>
    }
    return null
  }

const getData = useCallback(async ({search,sort}) => {
  return filterData({ data:events, search, sort })
  }, [events],
);

const filterData = ({data, ...args}) => {
  let filteredData = data;
  const { search, sort } = args;

  // Implement text-based query filtering
  if (search) {
    filteredData = filteredData.filter((row) =>
      Object.values(row).some((value) =>
        value.toString().toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }

  // Implement sorting
  if (sort && sort.length > 0) {
    filteredData.sort((a, b) => {
      for (const { id, desc } of sort) {
        const aValue = a[id];
        const bValue = b[id];

        if (aValue < bValue) return desc ? 1 : -1;
        if (aValue > bValue) return desc ? -1 : 1;
      }

      return 0;
    });
  }

  return filteredData;
};

  const columns = [
    {
        accessorKey: "label",
        header: t('event-table-header-label',{ns: 'events'}),
    },
    {
        Cell: ({ cell }) => dayjs(cell.getValue()).format("DD MMM 'YY"),
        accessorKey: "eventStart",
        header: t('event-table-header-eventStart',{ns: 'events'}),
    },
    {
        Cell: ({ cell }) => labContext.labs.find((lab)=> lab.id === cell.getValue())?.title,
        accessorKey: "labContent",
        header: t('event-table-header-content',{ns: 'events'}),
    },
    {
        accessorKey: "type",
        header: t('event-table-header-type',{ns: 'events'}),
    },
    {
        Cell: ({ cell }) => EventStatus({approvalState: cell.getValue()}),
        accessorKey: "state",
        header: t('event-table-header-state',{ns: 'events'}),
    },
  ]
  return (
          <DataTable
            columns={columns}
            getData={getData}
            hasPagination={false}
            getRowId={(row) => row.email}
            hasColumnResizing
            hasSearch
            hasFilters={true}
            hasColumnVisibility
            hasSorting={false}
            rowActionButtons={rowMenuItems}
            emptyPlaceholder={
              <DataTableEmptyState
                heading={t('event-table-empty-state-heading',{ns:'events'})}
                text={<>{t('event-table-empty-state-text',{ns:'events'})}</>}
              />
            }
            noResultsPlaceholder={<DataTableEmptyState
              heading={t('event-table-no-results-heading',{ns:'events'})}
              text={t('event-table-no-results-text',{ns:'events'})}
            />}
          />
  );
};
export default EventsTable;
