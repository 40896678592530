import { useLabContext } from "../../LabContext";
import { Box, TextField, Button, Toast, Callout } from "@okta/odyssey-react-mui";
import { CopyIcon } from "@okta/odyssey-react-mui/icons";
import {Spacing3} from "@okta/odyssey-design-tokens"
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import EditableTextField from "../fields/EditableTextField";
import EditableSelectField from "../fields/EditableSelectField";
import EditableDateField from "../fields/EditableDateField";
import { updateEvent } from "../../services/EventsAPI";

function EventDetailsTab({event, setEvent, setError}) {
    const { getAccessTokenSilently } = useAuth0();
    const [copyToastVisible, setCopyToastVisible] = useState(false);
    const labContext = useLabContext();
    const {t} = useTranslation();

    return (  
        <Box sx={{display:'flex', flexDirection: 'column'}}>
        <Box sx={{display:'flex', gap:'1rem', paddingBottom:Spacing3}}>
            <TextField
                isReadOnly
                label={t('event-code',{ns:'events'})}
                value={event.eventCode}
                endAdornment={<Button variant='floating' startIcon={<CopyIcon/>} onClick={()=>{navigator.clipboard.writeText(window.location.host+'/'+event.eventCode);setCopyToastVisible(true)}}/>}/>
            <Toast severity='info' isVisible={copyToastVisible} text="Copied!" onHide={()=>setCopyToastVisible(false)} autoHideDuration='1000'/>
        </Box>
        <EditableTextField
            label={t('createForm-eventTitle',{ns:'events'})}
            hint={t('createForm-eventTitleHint',{ns:'events'})}
            value={event.label}
            onSave={(value)=>{
                getAccessTokenSilently()
                .then((at) =>
                    updateEvent(at,event.eventCode,value,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined)
                        .then((response)=>setEvent(response.data))
                        .catch((err)=> setError(err)))
            }}
        />
        <EditableTextField
            label={t('createForm-eventDescription',{ns:'events'})}
            value={event.description}
            multiline={true} 
            onSave={(value)=>{
                getAccessTokenSilently()
                .then((at) =>
                    updateEvent(at,event.eventCode,undefined,value,undefined,undefined,undefined,undefined,undefined,undefined,undefined)
                        .then((response)=>setEvent(response.data))
                        .catch((err)=> setError(err)))
            }}
        />
        {event.state === 'approved'&&
            <Box sx={{maxWidth: '55ch', paddingBottom: Spacing3}}>
                <Callout severity='info' title={t('event-approval-reset-title',{ns:'events'})} text={t('event-approval-reset',{ns:'events'})}/>
            </Box>
        }
        <EditableDateField
            label={t('event-date',{ns:'events'})}
            startValue={event.eventStart}
            endValue={event.eventEnd}
            onSave={(start,end)=>{
                getAccessTokenSilently()
                .then((at) =>
                    updateEvent(at,event.eventCode,undefined,undefined,undefined,undefined,undefined,start,end,undefined,undefined)
                        .then((response)=>setEvent(response.data))
                        .catch((err)=> setError(err)))
            }}
        /> 
        <EditableSelectField
            label={t('createForm-eventType',{ns:'events'})} 
            hint={t('createForm-eventTypeHint',{ns:'events'})}
            value={event.type}
            onSave={(value)=>{
                getAccessTokenSilently()
                .then((at) =>
                    updateEvent(at,event.eventCode,undefined,undefined,value,undefined,undefined,undefined,undefined,undefined,undefined)
                        .then((response)=>setEvent(response.data))
                        .catch((err)=> setError(err)))
            }}
            options={[{text:'Pipeline Generation', value:'pipeline'},{text:'Customer Workshop', value:'workshop'}, {text:'Proof of Concept', value:'poc'}]}/>
        <EditableSelectField
            label={t('createForm-content',{ns:'events'})}
            hint={t('createForm-contentHint',{ns:'events'})}
            value={event.labContent}
            onSave={(value)=>{
                getAccessTokenSilently()
                .then((at) =>
                    updateEvent(at,event.eventCode,undefined,undefined,undefined,value,undefined,undefined,undefined,undefined,undefined)
                        .then((response)=>setEvent(response.data))
                        .catch((err)=> setError(err)))
            }}
            options={labContext.labs.filter((lab)=> lab.state === 'active').map((lab)=>{return {value:lab.id, text:lab.title}})} 
        />
        <EditableTextField
            label={t('createForm-participantCount',{ns:'events'})}
            hint={t('createForm-participantCountHint',{ns:'events'})}
            value={event.expectedParticipantCount}
            onSave={(value)=>{
                getAccessTokenSilently()
                .then((at) =>
                    updateEvent(at,event.eventCode,undefined,undefined,undefined,undefined,parseInt(value),undefined,undefined,undefined,undefined)
                        .then((response)=>setEvent(response.data))
                        .catch((err)=> setError(err)))
            }}
            type='number'
        />
        
    </Box>
     );
}

export default EventDetailsTab;