import React from 'react'
import { Loader, Dimmer } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const EmbeddedGuide = ({ component }) => {

    const { t } = useTranslation();

    if(component === undefined){
        return (
            <Dimmer active inverted id="innerDimmer">
                <Loader inverted>{t('loading')}</Loader>
            </Dimmer>
        )
    }

    if(component.state === 'deploying'){
        return (
            <Dimmer active inverted id="innerDimmer">
                <Loader inverted>{t('componentDeploying')}</Loader>
            </Dimmer>
        )
    }
    if(component.state === 'error'){
        return (
            <>
                <h3>{t('error-heading')}</h3>
                <span>{t('error-persistent')}</span>
            </>
        )
       
    }

    if(component.launchUrl){
        return (
            <iframe
            title='embedded-guide'
            min-width='1140px'
            min-height='640px'
            width='100%'
            sandbox='allow-same-origin allow-scripts allow-popups allow-forms allow-modals allow-top-navigation-by-user-activation'
            src={component.launchUrl}
            style={{ border: 0, flexGrow: 1 }}
        />
        )
    } else{
        <span><b>Cause:</b>Embedded guide component attached without launch url.</span>
    }
}

export default EmbeddedGuide