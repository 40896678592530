import { useState } from 'react';
import { Button, Container, Icon, Accordion, AccordionContent } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
const ResourceComponent = ({ resource }) => {
    const { t } = useTranslation();

    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    function handleCopyAction(copyValue) {
        navigator.clipboard.writeText(copyValue)
    }

    return (
        <Container>
            {resource ? (
                <Container className='component columnLayout'>
                    <span>
                        {resource.label}
                    </span>

                    {resource.deploymentData && resource.deploymentData.TenantName &&
                        <span><div className='tenantName'>{resource.deploymentData.TenantName} <Icon className='clickable' name='copy' onClick={() => handleCopyAction(resource.deploymentData.TenantName)} /></div></span>
                    }

                    {resource.launchUrl &&
                        < Button
                            className='launchButton'
                            size='mini'
                            icon='share square'
                            content={t('launch')}
                            labelPosition='right'
                            onClick={() => window.open(resource.launchUrl)}
                            disabled={resource.state !== "active"}
                        />
                    }
                    {resource.credentials &&
                        (
                            <Container>
                                <Accordion.Title>
                                    < Button onClick={handleToggleCollapse} active={isCollapsed} className='credentialButton' size='tiny' compact icon='key' toggle />
                                </Accordion.Title>
                                <AccordionContent active={isCollapsed}>
                                    <div>
                                        {resource.credentials.clientId &&
                                            <div >
                                                <div>
                                                    <div><b>{t('clientId')}</b></div>
                                                    <div className='credentialText'>{resource.credentials.clientId} <Icon className='clickable' name='copy' onClick={() => handleCopyAction(resource.credentials.clientId)} /></div>
                                                </div>
                                                <div>
                                                    <div><b>{t('clientSecret')}</b></div>
                                                    <div className='credentialText'>{resource.credentials.clientSecret} <Icon className='clickable' name='copy' onClick={() => handleCopyAction(resource.credentials.clientSecret)} /></div>
                                                </div>
                                                <div><a target='_blank' rel='noreferrer' href={resource.credentials.discoveryUrl}>{t('metadata')} <Icon name='share square' /></a></div>
                                            </div>
                                        }
                                        {resource.credentials.username &&
                                            <div>
                                                <div>
                                                    <div><b>{t('username')}</b></div>
                                                    <div className='credentialText'>{resource.credentials.username} <Icon className='clickable' name='copy' onClick={() => handleCopyAction(resource.credentials.username)} /></div>
                                                </div>
                                                <div>
                                                    <div><b>{t('password')}</b></div>
                                                    <div className='credentialText'>{resource.credentials.password} <Icon className='clickable' name='copy' onClick={() => handleCopyAction(resource.credentials.password)} /></div>
                                                </div>
                                            </div>
                                        }
                                        {resource.credentials.apiKey &&
                                            <div>
                                                <div>
                                                    <div><b>{t('apiKey')}</b></div>
                                                    <div className='credentialText'>{resource.credentials.apiKey} <Icon className='clickable' name='copy' onClick={() => handleCopyAction(resource.credentials.apiKey)} /></div>
                                                </div>
                                            </div>
                                        }
                                        {resource.deploymentData && resource.settings.showDeploymentData &&
                                            <div>
                                                {Object.keys(resource.deploymentData).map((key) => (
                                                    <div className='credentialText'>
                                                        <div><b>{key}</b></div>
                                                        <div>{resource.deploymentData[key]}  <Icon className='clickable' name='copy' onClick={() => handleCopyAction(resource.deploymentData[key])} /></div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </AccordionContent>
                            </Container>
                        )
                    }
                    {resource.state === "error" &&
                        <span>{t('componentError')}</span>
                    }
                    {resource.state === "queued" &&
                        <span>{t('componentDeploying')}</span>
                    }
                </Container>
            )
                : (<Container></Container>)
            }
        </Container>
    )

}
export default ResourceComponent