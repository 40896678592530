import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LabCatalogCard from "./LabCatalogCard";
import { useLabContext } from "../LabContext";
import { useTranslation } from 'react-i18next';
import { useDemoContext } from "../DemoContext";
import { Box, Typography } from "@okta/odyssey-react-mui";

const LabCatalog = () => {

    const { t } = useTranslation();
    const [availableLabs, setAvailableLabs] = useState([])
    const [waiting, setWaiting] = useState(false);
    const navigate = useNavigate();
    const labContext = useLabContext();
    const demoContext = useDemoContext();

    function createLab(lab) {
        setWaiting(true)
        navigate('/lab/create', { replace: true, state: { lab: lab } })
    }

    useEffect(() => {
        setAvailableLabs(labContext.labs.filter(lab => lab && demoContext.demos.find(demo => demo.labId === lab.id) === undefined && lab.state !== 'hidden'))
    }, [labContext.labs, demoContext.demos])


    return (
        <div className="appComponent">
            <Box>
                <Typography variant="h1">{t('catalog-heading')}<Typography variant="support">{t('catalog-subheading')}</Typography></Typography>
                {availableLabs?.length > 0 ?
                    (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', marginTop:'1.14285614rem'}}>
                            {
                                availableLabs.map((lab, index) =>
                                    <LabCatalogCard lab={lab} waiting={waiting} attachEvent={createLab} key={lab.id + "" + index} />
                                )}
                        </Box>
                    ) : (
                        <span>{t('catalog-empty')}</span>
                    )}
            </Box>
        </div>
    );
};
export default LabCatalog;
