import { createContext, useContext, useState, useEffect, useCallback } from "react";
import flagsmith from "flagsmith";
import { useFlagsmithLoading } from "flagsmith/react";

const LabContext = createContext({ labs: {}, setLabs: () => { }, isReady: {}, refresh: () => { } })

const LabContextProvider = ({ children }) => {
    const [labs, setLabs] = useState([])
    const [isReady, setIsReady] = useState(false)
    const flagsmithState = useFlagsmithLoading()

    const refresh = useCallback(() => {
        let allFlags = flagsmith.getAllFlags()
        var labsTemp = []
        let labsFlags = Object.keys(allFlags)
            .filter((key) => key.startsWith('lab_'));
        labsFlags.forEach(lab => {
            if (allFlags[lab].enabled) {
                try {
                    labsTemp.push(JSON.parse(allFlags[lab].value))
                } catch (err) {
                    console.log("Failed to log lab: " + lab)
                }
            }
        });
        setLabs(labsTemp)
        setIsReady(!flagsmithState.isLoading && !flagsmithState.isFetching)
    }, [flagsmithState.isLoading, flagsmithState.isFetching])

    useEffect(() => {
        refresh()
    }, [refresh, flagsmithState.isFetching])

    return (
        <LabContext.Provider value={{ labs, setLabs, isReady, refresh }}>
            {children}
        </LabContext.Provider>
    )
}
export const useLabContext = () => useContext(LabContext)
export default LabContextProvider