import React, { useEffect, useState } from "react";
import flagsmith from "flagsmith";
import { useFlagsmithLoading } from "flagsmith/react";
import { Box, Typography } from "@okta/odyssey-react-mui";

const EmployeeResources = () => {

    const flagsmithState = useFlagsmithLoading();
    const [connection, setConnection] = useState()

    useEffect(() => {
        if (!flagsmithState.isLoading && !flagsmithState.isFetching) {
            setConnection(flagsmith.getTrait("connection"))
        }
    }, [flagsmithState])


    if (connection === "employee") {
        return (
            <Box>
                <Typography variant='support'><a href="https://form.asana.com/?k=LZ6lybExRJbxw6n1_SDyIA&d=10114792544943" target="_blank" rel="noreferrer">Deliver a lab</a></Typography>
                <Typography variant='support'><a href="https://okta-ep--simpplr.vf.force.com/apex/simpplr__app?u=/site/a145f000000327zAAA/page/a125f0000011La7AAE" target="_blank" rel="noreferrer">Create a lab</a></Typography>
            </Box>
        );
    } else {
        return (null)
    }
};
export default EmployeeResources;
