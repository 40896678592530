import { TextField, Button,Box } from "@okta/odyssey-react-mui";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CalendarIcon } from "@okta/odyssey-react-mui/icons";
import dayjs from "dayjs";

const EditableDateField = ({label, startValue, endValue, onSave}) => {
    const {t} = useTranslation();
    const [editedStartValue, setEditedStartValue] = useState(startValue);
    const [editedEndValue, setEditedEndValue] = useState(startValue);

    const[isEditMode,setIsEditMode] = useState(false)
    if(isEditMode){
        return (
            <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker name="start" label='Event Start' value={dayjs(startValue)} onChange={(e)=>setEditedStartValue(e)}/>
                    <DateTimePicker name="end" label='Event End' value={dayjs(endValue)} onChange={(e)=>setEditedEndValue(e)}/>
                </LocalizationProvider>
                <Box sx={{maxWidth: '55ch'}}>
                    <Box sx={{display:'flex', justifyContent:'end'}}>
                        <Button variant='floating' label={t('cancel',{ns:'events'})} onClick={() => setIsEditMode(false)}/>
                        <Button label={t('save',{ns:'events'})} onClick={()=>{console.log(editedEndValue);onSave(dayjs(editedStartValue).utc().format(),dayjs(editedEndValue).utc().format()); setIsEditMode(false)}}/>
                    </Box>
                </Box>
            </>
        )
    } else {
        return (
            <TextField label={label} isReadOnly value={dayjs(startValue).format("DD MMM YYYY HH:mm") + '-' + dayjs(endValue).format("DD MMM YYYY HH:mm")} startAdornment={<CalendarIcon/>} endAdornment={<Button label={t('edit',{ns:'events'})} onClick={()=>{setEditedStartValue(startValue);setEditedEndValue(endValue);setIsEditMode(true)}}/>}/>
        )
    }
}
export default EditableDateField