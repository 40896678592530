import { Box, Typography, Switch } from "@okta/odyssey-react-mui";
import AttendeeTable from "../tables/AttendeeTable";
import ErrorMessage from "../ErrorMessage";
import { useInterval } from "../../useInterval";
import { useEffect, useCallback, useState } from "react";
import { getEventProgress } from "../../services/EventsAPI";
import { useAuth0 } from "@auth0/auth0-react";

function AttendeesTab({event}) {

    const [pollFrequency, setPollFrequency] = useState(null)
    const [error, setError] = useState()
    const {getAccessTokenSilently} = useAuth0();
    const [attendeeData, setAttendeeData] = useState()

    useInterval(async () => {
        getData()
      }, pollFrequency)

    const getData = useCallback(async () => {
        setError(null)
        try{
            const data = await getEventProgress(await getAccessTokenSilently(),event.eventCode);
            setAttendeeData(data.data)
        } catch(error) {
            setError(error)
            setAttendeeData([])
        }
    }, [ getAccessTokenSilently,event]);

    useEffect(() => {
        getData()
    }, [getData]);


    return ( 
        <>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2.285rem'}}>
            <Typography variant="h1">Attendee Progress<Typography variant="support">The following users are working through the lab with this event code</Typography></Typography>
                <Box sx={{ justifyContent: 'flex-end', display:'flex' }}>
                    <Box sx={{display: 'flex', flexDirection:'column'}}>
                        <Switch label="Automatic Refresh" isChecked={pollFrequency ? true : false} onChange={(e)=>e.checked ? setPollFrequency(60000):setPollFrequency(null)}/>
                    </Box>
                </Box>
        </Box>
            {error && <ErrorMessage error={error}/>}
        <Box>
            <AttendeeTable eventCode={event.eventCode} data={attendeeData}/>
        </Box>
        </>
    );
}

export default AttendeesTab;