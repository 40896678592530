import { Button, Container } from "@mui/material";
import { Typography, Box, BreadcrumbList, Paragraph } from "@okta/odyssey-react-mui";
import { useState, useEffect, useCallback } from "react";
import CreateEventForm from "../components/forms/CreateEventForm";
import EventsTable from "../components/tables/EventsTable";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "@okta/odyssey-react-mui/icons";
import { useFlags } from "flagsmith/react";
import { useAuth0 } from "@auth0/auth0-react";
import { getEvents } from "../services/EventsAPI";

const Events = () => {
    const [createEventModalOpen, setCreateEventModalOpen] = useState(false);
    const flags = useFlags(['event_create']);
    const [events, setEvents] = useState([])
    const{ getAccessTokenSilently} = useAuth0();

    const getEventList = useCallback(async () => {
        getAccessTokenSilently().then((at)=> getEvents(at).then((response)=>setEvents(response.data)))
    }, [getAccessTokenSilently])

    useEffect(() => {
        getEventList()
    }, [getEventList]);

    return (
        <>
            <Container className='AppContent'>
                <div className="appComponent">
                    <BreadcrumbList children={[<RouterLink to='/'><HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /></RouterLink>,<Paragraph href="">Event Management</Paragraph>]} />
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2.285rem'}}>
                        <Typography variant="h1">Manage Events<Typography variant="support">Hands-on labs are an excellent way for prospects and customers to learn about and evaluate Okta's products.</Typography></Typography>
                        {flags.event_create.enabled && 
                            <Box sx={{ justifyContent: 'flex-end', display:'flex' }}>
                                <Button onClick={()=> setCreateEventModalOpen(true)}>Create Event</Button>
                            </Box>
                        }
                    </Box>
                    <EventsTable events={events}/>
                    <CreateEventForm open={createEventModalOpen} setOpen={setCreateEventModalOpen} completeFunc={getEventList}/>
                </div>
            </Container>
        </>
    )
}

export default Events;